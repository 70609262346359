import React from 'react'

export default () => {
  return (
    <section className="container mx-auto px-4 my-8 md:my-12">
      <h2 className="text-2xl md:text-4xl mb-4 text-center">OUR MODULES</h2>

      <p className="leading-7">
        Our modules incorporate state standards which are based on theory from
        science and philosophy regarding stages and progression of child
        development. The particular needs of very young children and the
        recognition of the importance of early childhood development have led to
        the development of many programs and approaches to nurturing and
        inspiring children prior to entrance to primary school. Emphasis to
        different degrees of emotional, cognitive, physical, and social
        development, have led to the creation of state standards by educators,
        government agencies, and accrediting bodies.
      </p>
    </section>
  )
}
