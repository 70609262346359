import React from 'react'

import Learning from 'static/svgs/learning.svg'
import Girl from 'static/images/program/science-girl.png'

export default () => {
  return (
    <section className="my-10 container mx-auto px-4">
      <h2 className="text-2xl md:text-4xl mb-4 text-center md:text-left">
        LEARNING AT <nobr>YOUR OWN PACE</nobr>
      </h2>

      <div className="md:flex flex-row mb-8 md:mb-20">
        <div className="md:w-3/5 md:mr-10">
          <div className="md:px-10">
            <p className="mb-4">
              Age indicators are a recommendation to match the child to a
              cognitively balanced level.
            </p>
            <p className="mb-10">
              Little Scholar Curriculum uses prior knowledge to build learning
              and meaning for students.
            </p>
          </div>
          <img src={Learning} alt="Learning levels" />
        </div>

        <div className="flex-1">
          <img className="hidden md:block" src={Girl} alt="" />
        </div>
      </div>
    </section>
  )
}
