import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { CgArrowLeftR, CgArrowRightR } from 'react-icons/cg'

import Abc from 'static/svgs/modules/abc.svg'
import Apple from 'static/svgs/modules/apple.svg'
import Crayons from 'static/svgs/modules/crayons.svg'
import Heart from 'static/svgs/modules/heart.svg'
import Puzzle from 'static/svgs/modules/puzzle.svg'
import Rocket from 'static/svgs/modules/rocket.svg'

export default () => {
  const breakpoints = useBreakpoint()
  const [current, setCurrent] = useState(0)
  const modules = moduleData

  const goLeftHandler = event => {
    const next = Math.abs((current - 1 + modules.length) % modules.length)
    setCurrent(next)
  }

  const goRightHandler = event => {
    const next = Math.abs((current + 1) % modules.length)
    setCurrent(next)
  }

  const handleIndicatorClick = index => event => {
    setCurrent(index)
  }

  return (
    <section className="bg-primary-200 md:py-8">
      <div className="container mx-auto md:px-4 py-4 md:py-0">
        <h2 className="px-4 mb-4 text-2xl md:text-4xl text-center font-sans">
          The Little Scholar Modules
        </h2>

        <div className="overflow-hidden">
          <div
            css={[
              tw`flex flex-row border-gray-500 border-b-2 border-t-2`,
              css`
                width: ${modules.length * 100}%;
                margin-left: -${current * 100}%;
                transition: margin-left 0.5s ease-in;
              `,
            ]}
          >
            {modules.map(({ icon, name, subtitle, fields }) => (
              <div
                key={name}
                className="px-4 md:py-4 mb-4 h-full md:mb-0 w-screen flex flex-col md:flex-row md:justify-center md:items-start"
              >
                <div className="md:w-1/3 flex justify-center items-center">
                  <div className="mx-auto md:mx-0 my-4 h-24 w-24 md:w-40 md:h-40 rounded-full bg-white flex items-center justify-center">
                    <img src={icon} alt="" className="h-16 md:h-32" />
                  </div>
                </div>

                <div className="flex-1">
                  <h3 className="mb-2 font-bold text-lg md:text-2xl">{name}</h3>

                  <ul
                    className="ml-4 md:ml-0 md:flex md:flex-col md:flex-wrap"
                    css={[
                      breakpoints.md &&
                        css`
                          max-height: 350px;
                        `,
                    ]}
                  >
                    {fields.map(({ name, subfields }) => (
                      <li key={name} className="mb-2">
                        <h4 className="font-weight-600 text-base text-black">
                          {name}
                        </h4>

                        {subfields && (
                          <ul className="ml-4">
                            {subfields.map(subfield => (
                              <li key={subfield}>{subfield}</li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="my-4 flex flex-row justify-center">
            {modules.map((_, index) => (
              <button
                key={index}
                className="mr-2 last:mr-0 h-6 w-6 rounded-full flex items-center justify-center bg-primary-600 hover:bg-primary-800 text-gray-200 focus:outline-none"
                css={[current === index && tw`bg-primary-900`]}
                onClick={handleIndicatorClick(index)}
              >
                {index}
              </button>
            ))}
          </div>

          <div className="mt-4 flex flex-row justify-center">
            <button className="mx-2" onClick={goLeftHandler}>
              <CgArrowLeftR size={30} />
            </button>
            <button className="mx-2" onClick={goRightHandler}>
              <CgArrowRightR size={30} />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

const moduleData = [
  {
    icon: Puzzle,
    name: 'Approaches to Learning Through Play',
    subtitle: 'Constructing, Organizing & Applying Knowledge',
    fields: [
      {
        name: 'Constructing & Gathering Knowledge',
      },
      {
        name: 'Oraganzing & Understanding Information',
      },
      {
        name: 'Applying Knowledge',
      },
      {
        name: 'Learning through Experience & Natural Opportunities',
      },
    ],
  },
  {
    icon: Abc,
    name: 'Language & Literacy Development',
    subtitle: 'English Language Arts',
    fields: [
      {
        name: 'Foundational Skills',
      },
      {
        name: 'Reading Informational Test',
      },
      {
        name: 'Reading Literature',
      },
      {
        name: 'Writing',
      },
      {
        name: 'Speaking & Listening',
      },
      {
        name: 'Critical Thinking through Research',
      },
    ],
  },
  {
    icon: Rocket,
    name: 'Scientific Thinking & Technology',
    subtitle: 'Exploring, Scientific Inquiry, and Discovery',
    fields: [
      {
        name: 'Biological Sciences',
        subfields: ['Living & Non-Livign Organisms', 'Genetics', 'Evolution'],
      },
      {
        name: 'Physical Sciences',
        subfields: ['Chemistry', 'Physics'],
      },
      {
        name: 'Earth & Space Sciences',
        subfields: [
          'Earth Structures, Processes & Cycles',
          'Origin & Evolution of the Universe',
        ],
      },
      {
        name: 'Environment & Ecology',
        subfields: [
          'Ecology',
          'Watersheds & Wetlands',
          'Natural Resources',
          'Agriculture & Society',
          'Humans & the Environment',
        ],
      },
    ],
  },
  {
    icon: Crayons,
    name: 'Creative Thinking & Expression',
    subtitle: 'Communicating through the Arts',
    fields: [
      {
        name: 'Music & Movement',
      },
      {
        name: 'Dramatic & Performance Play',
      },
      {
        name: 'Visual Arts',
      },
      {
        name: 'Historical & Cultural Context of Works in the Arts',
      },
      {
        name: 'Critical Response to Wroks in the Arts',
      },
      {
        name: 'Aesthetic Response to Works in the Arts',
      },
    ],
  },
  {
    icon: Apple,
    name: 'Health Wellness & Physical Development',
    subtitle: 'Learning About My Body',
    fields: [
      {
        name: 'Physical Activity',
        subfields: ['Gross Motor Coordination'],
      },
      {
        name: 'Concepts, Principles, and Strategies of Movement',
        subfields: ['Fine Motor Coordination'],
      },
      {
        name: 'Concepts of Health',
      },
      {
        name: 'Healthful Living',
      },
      {
        name: 'Safety & Injury Prevention',
      },
    ],
  },
  {
    icon: Heart,
    name: 'Social & Emotional DevelopmentPlay',
    subtitle: 'Student Interpersonal Skills',
    fields: [
      {
        name: 'Self-Awareness & Self management',
      },
      {
        name: 'Establishing & Maintaining Relationships',
      },
      {
        name: 'Decision-Making & Responsible Behavior',
      },
    ],
  },
]
