import React from 'react'

import Gears from 'static/svgs/gears-program.svg'
import Gear1 from 'static/svgs/number-gear/number-gear-1.svg'
import Gear2 from 'static/svgs/number-gear/number-gear-2.svg'
import Gear3 from 'static/svgs/number-gear/number-gear-3.svg'
import Gear4 from 'static/svgs/number-gear/number-gear-4.svg'
import Gear5 from 'static/svgs/number-gear/number-gear-5.svg'
import Gear6 from 'static/svgs/number-gear/number-gear-6.svg'

export default () => {
  const gears = [
    {
      src: Gear1,
      content: 'Learning standards provide the framework for learning.',
    },
    {
      src: Gear2,
      content:
        'Professionals must use both informal (naturally occurring observations) and formal (standardized tests) assessments to understand children’s progress.',
    },
    {
      src: Gear3,
      content:
        'A curriculum framework informs us what information should be taught to young children within each of the learning domains.',
    },
    {
      src: Gear4,
      content:
        'Teacher instruction in these early years often looks different. Learning occurs within natural opportunities and the context of play utilizing active learning strategies where children are engaged in concrete and hands-on discovery, experimentation, and interaction with materials, their peers, and adults that facilitate the learning.',
    },
    {
      src: Gear5,
      content:
        'Well-designed environments demonstrate a commitment to the whole child by offering materials and activities that promote social, physical, cognitive, and language learning.',
    },
    {
      src: Gear6,
      content:
        'A safe and supportive learning environment is key to providing children the opportunity to take risks and explore their environment to enrich their level of understanding.',
    },
  ]

  return (
    <section className="">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-4xl mb-4 text-center md:text-left">
          OUR PHILOSOPHY
        </h2>

        <div className="md:flex flex-row items-center mb-8">
          <div className="mb-4 md:w-1/2 md:mr-8 leading-8 text-center md:text-left">
            <p className="mb-4">
              Individuals are born with an{' '}
              <span className="font-bold">
                incredible capacity and innate desire to learn.
              </span>{' '}
              As we developed our Little Scholar Curriculum, we took into
              consideration the multitude of research that confirms the
              foundational importance of early childhood education and care for
              children’s school and success in their educational journey and
              life.
            </p>
            <p>
              It is essential that a child’s first educational experience is one
              that incorporates{' '}
              <span className="font-bold">
                robust developmentally appropriate
              </span>{' '}
              activities that develop{' '}
              <span className="font-bold">critical thinking</span> and{' '}
              <span className="font-bold">problem-solving skills </span>
              while providing a deep understanding about themselves and the
              world in which they live.
            </p>
          </div>

          <img src={Gears} alt="" className="md:w-1/2" />
        </div>

        <p
          className="text-center max-w-4xl mx-auto mb-8"
          // className="pb-4 border-b-4 border-accent-600"
        >
          To ensure student achievement and academic excellence, there must be a
          standards aligned system in place. Our assessment and curriculum,
          teacher training and academic materials have been developed with this
          aligned system in mind.
        </p>
      </div>

      <div className="bg-primary-200 py-8 md:pt-12 px-4">
        <ol className="container mx-auto md:grid md:grid-flow-col md:grid-rows-3 md:gap-x-20 md:gap-y-8">
          {gears.map(({ src, content }, index) => (
            <li key={index} className="flex flex-row mb-4 md:mb-0">
              <img src={src} alt="" className="h-12 mr-4" />
              <p className="mt-2">{content}</p>
            </li>
          ))}
        </ol>
      </div>
    </section>
  )
}
