import React from 'react'
import { css } from 'twin.macro'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import MiloProgram from 'static/svgs/milo-program.svg'
import MillieProgram from 'static/svgs/millie-program.svg'

export default () => {
  const breakpoints = useBreakpoint()

  const additionalProducts = [
    'Classroom Poster (Alphabet, Song lyrics, Number 1-10, Shapes, etc)',
    'Stuffed animal Millie and Milo characters',
    'Training Videos/Posters',
    'Animated Shows',
  ]

  return (
    <section className="container mx-auto px-4 pb-8">
      <h2 className="text-2xl md:text-4xl mb-4">
        <span className="text-primary-700">LEARN WITH</span>{' '}
        <nobr>MILO &amp; MILLIE</nobr>
      </h2>

      <div className="flex flex-col md:flex-row md:items-start">
        <div className="md:w-9/12 lg:w-1/2 md:mr-4">
          <p className="mb-4">
            As a young robot, Milo came to America to study. It was here that he
            met his best friend Millie! Together they began an adventure to grow
            and learn all about the world in which they live. Milo and Millie
            were developed to help children in all countries develop their
            English language skills through developmentally appropriate
            activities. It is our hope that Milo and Millie become a part of
            your learning environment and through the motivating activities,
            your students will want to be live Milo and Millie as they learn
            about the world around them.
          </p>

          <h3 className="mb-4 text-xl font-semibold font-serif">
            ADDITIONAL PROUDCTS
          </h3>
          <ul className="list-disc list-inside">
            {additionalProducts.map((product, index) => (
              <li className="mb-2" key={index}>
                {product}
              </li>
            ))}
          </ul>
        </div>

        <div className="md:ml-4 flex flex-row justify-center items-end md:justify-start">
          <img
            src={MiloProgram}
            alt=""
            css={[
              css`
                margin-right: -40px;
                height: ${breakpoints.lg ? 400 : breakpoints.md ? 240 : 240}px;
                max-height: 400px;
                z-index: 1;
              `,
            ]}
          />
          <img
            src={MillieProgram}
            alt=""
            css={[
              css`
                height: ${breakpoints.lg ? 380 : breakpoints.md ? 230 : 230}px;
                max-height: 400px;
              `,
            ]}
          />
        </div>
      </div>

      <div className="my-10">
        <div className="mx-auto max-w-xl border-b-2 border-primary-700 border-opacity-50"></div>
      </div>

      <p className="mx-auto max-w-4xl text-center leading-7">
        The Little Scholar curriculum is fully aligned with the State Standards
        . The Little Scholar core program is made of the Language Development,
        STREAM (Science, Technology, Reading/Research, Engineering, Arts and
        Math) and Culture, which form the backbone of the learners’ experience.
        Students are groomed in the fundamentals which are methodologically
        infused into the syllabus, allowing your child to be an adaptable and
        interdisciplinary individual, with the ability to thrive in the
        ever-changing 21st century.
      </p>
    </section>
  )
}
