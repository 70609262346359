import React from 'react'
import tw from 'twin.macro'

import BookIcon from 'static/svgs/book.svg'
import StreamIcon from 'static/svgs/stream.svg'
import CultureIcon from 'static/svgs/culture.svg'

export default () => {
  const plans = planData

  return (
    <div className="container mx-auto px-4">
      <p className="mx-auto text-center max-w-4xl mb-4 md:mb-16">
        The Little Scholar curriculum is fully aligned with the state standards
        and. The Little Scholar core program is made up of Language Development,
        STREAM (Science, Technology, Research, Engineering, Arts, Mathematics)
        and Culture.
      </p>

      <div>
        <ol>
          {plans.map(({ age, levels, details }, index) => (
            <li key={index}>
              <div className="mb-4 flex items-end flex-row-reverse">
                <h2 className="text-xl font-sans">{`Age: ${age} | Levels: ${levels}`}</h2>
                <div className="flex-1 mx-1 mb-2 border-b-2 border-accent-500"></div>
              </div>

              <ul className="md:grid md:grid-flow-col">
                {details.map(({ header, description, list }, dIndex) => (
                  <li
                    key={dIndex}
                    className="mb-4 md:px-6 md:border-r md:last:border-r-0"
                  >
                    <div className="flex flex-row">
                      <img
                        className="h-8 mr-4"
                        alt=""
                        src={
                          header === 'Language Development'
                            ? BookIcon
                            : header === 'STREAM'
                            ? StreamIcon
                            : CultureIcon
                        }
                      />

                      <div>
                        <h3
                          css={[
                            tw`mb-2 text-lg font-bold font-sans`,
                            header === 'Language Development'
                              ? tw`text-blue-600`
                              : header === 'STREAM'
                              ? tw`text-red-800`
                              : tw`text-yellow-700`,
                          ]}
                        >
                          {header}
                        </h3>

                        <p className="mb-4">{description}</p>
                        <ul className="list-inside list-disc">
                          {list.map((listItem, lIndex) => (
                            <li key={lIndex}>{listItem}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

const planData = [
  {
    age: '3 - 4',
    levels: '1 - 2',
    details: [
      {
        header: 'Language Development',
        description:
          'Build a strong foundation in oral communication of the English language with age appropriate vocabulary.',
        list: [
          'Basic English Phonics',
          'Developmentally Appropriate',
          'Student Activity Worksheets',
        ],
      },
      {
        header: 'STREAM',
        description:
          'Exposure to everyday occurrences, which are scientific in nature. Students learn to make observations, talk about these discoveries and make simple analysis of these learning opportunities.',
        list: ['Everyday Science', 'Making Observations'],
      },
      {
        header: 'Culture',
        description:
          'Learn about the four seasons and festivals celebrated in America.',
        list: ['Seasons', 'Festivals'],
      },
    ],
  },
  {
    age: '5+',
    levels: '3 - 4',
    details: [
      {
        header: 'Language Development',
        description:
          'Expand vocabulary and be confident in communicating thoughts and spice through writing in English.',
        list: [
          'Advanced English Phonics',
          'Vocabulary Emphasis',
          'Student Activity Worksheets',
        ],
      },
      {
        header: 'STREAM',
        description:
          'Progress to physical science, which includes astronomy, simple physics and the conducting simple experiments.',
        list: ['Physical Science', 'Science Experiments'],
      },
      {
        header: 'Culture',
        description:
          'Probe deeper into traditions and general history around America.',
        list: ['Traditions', 'Values'],
      },
    ],
  },
]
