import React from 'react'

import Layout from 'components/layouts/layout'
import SEO from 'components/seo'
import LearnWithMiloMillie from 'components/pages/program/learn-with-milo-millie'
import Elements from 'components/pages/program/elements'
import Philosophy from 'components/pages/program/philosophy'
import LearningAtYourOwnPace from 'components/pages/program/learning-at-your-own-pace'
import LessonPlan from 'components/pages/program/lesson-plan'
import OurModules from 'components/pages/program/our-modules'
import TheLittleScholarModules from 'components/pages/program/modules-slider'

/**
 * Our Program Page
 */
export default ({ location }) => (
  <Layout title="Our Program" fullWidth={true}>
    <SEO title="Our Program" path={location.pathname} />

    <LearnWithMiloMillie />
    <Elements />
    <Philosophy />
    <LearningAtYourOwnPace />
    <Divider />
    <LessonPlan />
    <Divider />
    <OurModules />
    <TheLittleScholarModules />
    <div className="h-20"></div>
  </Layout>
)

const Divider = () => {
  return (
    <div className="container mx-auto px-4 my-8">
      <div className="mx-auto md:my-20 max-w-2xl border-b-2 border-gray-400" />
    </div>
  )
}
